var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-1","title":"Tambah Jenis Pembayaran","ok-only":"","ok-title":"Accept","size":"lg","no-close-on-backdrop":"","hide-footer":""}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v(" Jenis Pembayaran")]),_c('validation-provider',{attrs:{"name":"Jenis Pembayaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"label":"nm_jenis_pembayaran","options":_vm.jenis_pembayaran},model:{value:(_vm.input.id_jenis_pembayaran),callback:function ($$v) {_vm.$set(_vm.input, "id_jenis_pembayaran", $$v)},expression:"input.id_jenis_pembayaran"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v(" Periode Semester")]),_c('validation-provider',{attrs:{"name":"Periode Semester","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"label":"nama","options":_vm.semester},model:{value:(_vm.input.id_semester),callback:function ($$v) {_vm.$set(_vm.input, "id_semester", $$v)},expression:"input.id_semester"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("No Rekening")]),_c('validation-provider',{attrs:{"name":"No Rekening","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"label":"atas_nama","options":_vm.rekening_bank},model:{value:(_vm.input.id_rekening),callback:function ($$v) {_vm.$set(_vm.input, "id_rekening", $$v)},expression:"input.id_rekening"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Kelas")]),_c('validation-provider',{attrs:{"name":"kelas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"state":errors.length > 0 ? false:null,"label":"nama","options":_vm.kelas},model:{value:(_vm.input.kelas),callback:function ($$v) {_vm.$set(_vm.input, "kelas", $$v)},expression:"input.kelas"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Nominal Bayar Rp.")]),_c('validation-provider',{attrs:{"name":"Nominall Pembayaran","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('currency-input',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.input.nominal),callback:function ($$v) {_vm.$set(_vm.input, "nominal", $$v)},expression:"input.nominal"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Tanggal Mulai")]),_c('validation-provider',{attrs:{"name":"Tanggal Mulai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.input.tgl_mulai),callback:function ($$v) {_vm.$set(_vm.input, "tgl_mulai", $$v)},expression:"input.tgl_mulai"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Tanggal Selesai")]),_c('validation-provider',{attrs:{"name":"Tanggal Selesai","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"state":errors.length > 0 ? false:null},model:{value:(_vm.input.tgl_selesai),callback:function ($$v) {_vm.$set(_vm.input, "tgl_selesai", $$v)},expression:"input.tgl_selesai"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('label',{attrs:{"for":"InputHelp"}},[_vm._v("Keterangan")]),_c('b-form-textarea',{attrs:{"id":"textarea-default","placeholder":"Textarea","rows":"3"},model:{value:(_vm.input.keterangan),callback:function ($$v) {_vm.$set(_vm.input, "keterangan", $$v)},expression:"input.keterangan"}})],1)],1)],1),_c('hr'),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-button',{staticClass:"mr-2",attrs:{"variant":"primary","size":"sm"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{attrs:{"icon":"AlignJustifyIcon","size":"15"}}),_vm._v(" Simpan ")],1),_c('b-button',{attrs:{"variant":"warning","size":"sm"},on:{"click":function($event){return _vm.close()}}},[_c('feather-icon',{attrs:{"icon":"XIcon","size":"15"}}),_vm._v(" Batalkan ")],1)],1)],1),_c('br')],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }