<template>
    <div>
        <b-modal id="modal-2" title="Edit Jenis Pembayaran" ok-only ok-title="Accept" size="lg" no-close-on-backdrop
            hide-footer>
            <validation-observer ref="simpleRules"> 
                <b-row v-if="jm < 1 "> 
                    <b-col md="12">
                        <b-form-group>
                            <label for="InputHelp"> Jenis Pembayaran</label>
                            <validation-provider #default="{ errors }" name="Jenis Pembayaran" rules="required">
                                <v-select v-model="input.id_jenis_pembayaran"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :state="errors.length > 0 ? false:null" label="nm_jenis_pembayaran"
                                    :options="jenis_pembayaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> 
                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp"> Periode Semester</label>
                            <validation-provider #default="{ errors }" name="Periode Semester" rules="required">
                                <v-select v-model="input.id_semester"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :state="errors.length > 0 ? false:null" label="nama" :options="semester" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp">No Rekening</label> 
                            <validation-provider #default="{ errors }" name="No Rekening" rules="required">
                                <v-select v-model="input.id_rekening"
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :state="errors.length > 0 ? false:null" label="atas_nama"
                                    :options="rekening_bank" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp">Kelas</label>
                            <validation-provider #default="{ errors }" name="kelas" rules="required">
                                <v-select v-model="input.kelas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :state="errors.length > 0 ? false:null" label="nama" :options="kelas" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group>
                            <label for="InputHelp">Nominal Bayar Rp.</label>
                            <validation-provider #default="{ errors }" name="Nominall Pembayaran" rules="required">
                                <currency-input  v-model="input.nominal" class="form-control"
                                    :state="errors.length > 0 ? false:null"   />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> 
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="jm  >= 1"> 
                    <b-col md="12">
                        <b-form-group>
                            <label for="InputHelp"> Jenis Pembayaran</label>
                            <validation-provider #default="{ errors }" name="Jenis Pembayaran" rules="required">
                                <v-select v-model="input.id_jenis_pembayaran" disabled=""
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :state="errors.length > 0 ? false:null" label="nm_jenis_pembayaran" 
                                    :options="jenis_pembayaran" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> 
                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp"> Periode Semester</label>
                            <validation-provider #default="{ errors }" name="Periode Semester" rules="required">
                                <v-select v-model="input.id_semester" disabled=""
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :state="errors.length > 0 ? false:null" label="nama" :options="semester" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>

                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp">No Rekening</label> 
                            <validation-provider #default="{ errors }" name="No Rekening" rules="required">
                                <v-select v-model="input.id_rekening" disabled=""
                                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                                    :state="errors.length > 0 ? false:null" label="atas_nama"
                                    :options="rekening_bank" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                        <b-form-group>
                            <label for="InputHelp">Kelas</label>
                            <validation-provider #default="{ errors }" name="kelas" rules="required">
                                <v-select v-model="input.kelas" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" disabled=""
                                    :state="errors.length > 0 ? false:null" label="id_kelas" :options="kelas" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>

                        <b-form-group>
                            <label for="InputHelp">Nominal Bayar Rp.</label>
                            <validation-provider #default="{ errors }" name="Nominall Pembayaran" rules="required">
                                <currency-input  v-model="input.nominal" class="form-control" disabled=""
                                    :state="errors.length > 0 ? false:null"   />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> 
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                    <b-col md="6">
                        <b-form-group>
                            <label for="InputHelp">Tanggal Mulai</label>
                            <validation-provider #default="{ errors }" name="Tanggal Mulai" rules="required">
                                <flat-pickr v-model="input.tgl_mulai" class="form-control"   :state="errors.length > 0 ? false:null"  />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> 

                        
                        </b-form-group>
                    </b-col>
                    <b-col md="6">
                        <b-form-group>
                            <label for="InputHelp">Tanggal Selesai</label>
                            <validation-provider #default="{ errors }" name="Tanggal Selesai" rules="required">
                                <flat-pickr v-model="input.tgl_selesai" class="form-control"    :state="errors.length > 0 ? false:null"  />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider> 
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col md="12">
                        <b-form-group>
                            <label for="InputHelp">Keterangan</label>
                            <b-form-textarea id="textarea-default" v-model="input.keterangan" placeholder="Textarea" rows="3" />
                        </b-form-group>
                    </b-col>
                </b-row>
                <hr>
                <b-row>
                    <b-col md="6">
                        <b-button variant="primary" class="mr-2" size="sm" @click.prevent="validationForm">
                            <feather-icon icon="AlignJustifyIcon" size="15" /> Simpan
                        </b-button>
                        <b-button variant="warning" @click="close()" size="sm">
                            <feather-icon icon="XIcon" size="15" /> Batalkan
                        </b-button>
                    </b-col>
                </b-row>
                <br>
            </validation-observer>
        </b-modal>
    </div>
</template>

<script>
    import CurrencyInput from './CurrencyInput.vue';
    import vSelect from 'vue-select'
    import flatPickr from 'vue-flatpickr-component'
    import {
        BForm,
        BCol,
        BModal,
        BButton,
        VBModal,
        BAlert,
        BFormInput,
        BRow,
        BFormGroup,
        BFormTextarea
    } from 'bootstrap-vue'
    import Ripple from 'vue-ripple-directive'
    import {
        ValidationProvider,
        ValidationObserver
    } from 'vee-validate'
    import {
        required
    } from '@validations' 
    export default {
        components: {
            BButton,
            BForm,
            BCol,
            BModal,
            BAlert,
            BFormInput,
            BRow,
            BFormGroup,
            vSelect,
            flatPickr,
            BFormTextarea,
            ValidationProvider,
            ValidationObserver,
            CurrencyInput,
            CurrencyInput
        },
        directives: {
            'b-modal': VBModal,
            Ripple,
        },

        props: {
            rekening_bank: {},
            jenis_pembayaran: {},
            semester: {},
            input : {}, 
            jm : {}
        },
        data() {
            return { 
                required, 
                kelas: [{
                        id_kelas: "Pagi",
                        nama: "pagi",
                    },
                    {
                        id_kelas: "Sore",
                        nama: "Sore",
                    },
                    {
                        id_kelas: "Pagi-Sore",
                        nama: "Pagi-Sore",
                    }
                ],
                date: null,
                dateDefault: null,
                timePicker: null,
                dateNtim: null,
                multiDate: null,
                rangeDate: null,
                humanDate: null,
                disableDate: null,
                inlineDate: null,
            }
        },
        emits: ['update'],
        methods: {
            validationForm() {
                this.$refs.simpleRules.validate().then(success => {
                    if (success) {
                        this.$emit('update', this.input);
                        this.$root.$emit('bv::hide::modal', 'modal-2');
                        this.input = {}
                        this.close()
                    }
                })
            },
            close() {
                this.$root.$emit('bv::hide::modal', 'modal-2')
            }
        }
    }
</script>
<style lang="scss">
    @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>