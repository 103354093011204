<template>
    <div>
        <b-card>
            <b-row>
                <b-col md="2" sm="4" class="my-1">
                    <b-form-group class="mb-0">
                        <label class="d-inline-block text-sm-left mr-50">Per page</label>
                        <b-form-select id="perPageSelect" v-model="perPage" size="sm" :options="pageOptions"
                            class="w-50" />
                    </b-form-group>
                </b-col>
                <b-col md="4" class="my-1">

                </b-col>
                <!-- <b-col md="3" sm="8" class="my-1">
                  
                </b-col> -->
                <b-col md="4" class="my-1">
                    <b-form-group label="" label-align-sm="left" label-size="sm" label-for="filterInput" class="mb-0">
                        <b-input-group size="sm">
                            <b-form-input id="filterInput" v-model="filter" type="search"
                                placeholder="Type to Search" />
                        </b-input-group>
                    </b-form-group>

                </b-col>
                <b-col md="2" class="my-1">
                    <b-button block size="sm" v-b-modal.modal-1 variant="primary">
                        <feather-icon icon="PlusIcon" size="15" /> add
                    </b-button>
                </b-col>

                <b-col cols="12">
                    <b-table striped hover responsive :per-page="perPage" :current-page="currentPage" :items="items"
                        :fields="fields" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc"
                        :sort-direction="sortDirection" :filter="filter" :filter-included-fields="filterOn"
                        @filtered="onFiltered"> 
                        <template #cell(deskripsi)="data"> 
                            <b-row>
                                <b-col md="4">
                                    <h4><small><strong>Pembayaran {{data.item.nm_jenis_pembayaran}}</strong></small>
                                        <br> Periode {{ data.item.nm_semester }}
                                    </h4>
                                    <b-row>
                                        <b-col md="12">
                                            <small>Periode pembayaran </small><br>
                                            <b-avatar variant="light-primary" rounded="" size="25" class="mr-1">
                                                <feather-icon icon="CalendarIcon" size="15" />
                                            </b-avatar>
                                            {{data.item.tgl_mulai}} - {{ data.item.tgl_selesai }}
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                        <b-col md="12">
                                            <b-avatar variant="light-primary" rounded="" size="25" class="mr-1">
                                                <feather-icon icon="AwardIcon" size="15" />
                                            </b-avatar>
                                            <strong>Kelas {{data.item.kelas}}</strong>
                                        </b-col>
                                    </b-row>
                                    <b-row class="mt-1">
                                        <b-col md="12">
                                            <b-avatar variant="light-primary" rounded="" size="25" class="mr-1">
                                                <feather-icon icon="UsersIcon" size="15" />
                                            </b-avatar>
                                            <small>Jumlah Transaksi <strong> {{data.item.jm}} X</strong></small>
                                        </b-col>
                                    </b-row>
                                </b-col>
                                <b-col md="3">
                                    <div variant="warning" show>
                                        <div class="alert-body">
                                            <small>Nominal Rp. </small>
                                            <h2 class="text-warning"><strong>{{ Rp(data.item.nominal) }}</strong></h2>
                                            <small>No Rekening</small>
                                            <h6 class="text-warning">Bank {{data.item.bank}} {{ data.item.no_rekening }}
                                            </h6>
                                            <small>Atasnama : </small>
                                            <small><strong> {{ data.item.atas_nama }}</strong></small>
                                        </div>
                                    </div>
                                </b-col>

                                <b-col md="5">
                                    <small>Keterangan</small><br>
                                    <p>{{ data.item.keterangan }}</p>
                                    <b-row style="margin-top: -10px;">
                                        <b-col md="12">
                                            <small class="text-primary">keterangan Jenis Pembayaran</small><br>
                                            <p v-html="data.item.keterangan_pembayaran"></p>
                                        </b-col>
                                    </b-row>
                                    <b-row style="margin-top: 10px;">
                                        <b-col md="6">
                                            <b-button block size="sm" variant="outline-warning"
                                                v-if="data.item.status =='0'" @click="updateStatus(data.item.id)">
                                                <feather-icon icon="AlertCircleIcon" size="15" /> Non Aktif
                                            </b-button>
                                            <b-button block size="sm" variant="outline-primary"
                                                v-if="data.item.status !='0'" @click="updateStatus(data.item.id)">
                                                <feather-icon icon="AlertCircleIcon" size="15" /> Aktif
                                            </b-button>
                                        </b-col>
                                        <b-col md="6">
                                            <b-button block size="sm" variant="outline-primary" v-b-modal.modal-2
                                                @click="getEdit(data.item.id, data.item.jm)">
                                                <feather-icon icon="EditIcon" size="15" /> Edit
                                            </b-button>
                                        </b-col>
                                    </b-row>
                                </b-col>
                            </b-row>
                        </template>
                    </b-table>
                </b-col>
                <b-col cols="6">
                    Jumlah Data <strong> {{ totalRows }} </strong> row
                </b-col>

                <b-col cols="6">
                    <b-pagination v-model="currentPage" :total-rows="totalRows" :per-page="perPage" align="right"
                        size="sm" class="my-0" />
                </b-col>
            </b-row>
        </b-card>
        <add :rekening_bank=rekening_bank :jenis_pembayaran=jenis_pembayaran :semester=semester :input=input
            @simpan=simpan></add>
        <detail :jm=jm :rekening_bank=rekening_bank :jenis_pembayaran=jenis_pembayaran :semester=semester :input=input
            @update=update></detail>
    </div>
</template>

<script>
    import Base from '@/config/Mixins_base';
    import axios from '@/config/Axios';
    import add from './component/add.vue'
    import detail from './component/detail.vue';
    import {
        BTable,
        BAvatar,
        BBadge,
        BRow,
        BCol,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BCard,
        BAlert,
        BLink
    } from 'bootstrap-vue'

    export default {
        components: {
            BTable,
            BAvatar,
            BBadge,
            BRow,
            BCol,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BButton,
            BCard,
            add,
            detail,
            BAlert,
            BLink,
        },
        mixins: [Base],

        data() {
            return {
                perPage: 5,
                pageOptions: [3, 5, 10],
                totalRows: 1,
                currentPage: 1,
                sortBy: '',
                sortDesc: false,
                sortDirection: 'asc',
                filter: null,
                filterOn: [],
                infoModal: {
                    id: 'info-modal',
                    title: '',
                    content: '',
                },
                fields: [{
                    key: 'deskripsi',
                    label: '',
                }],
                items: [],
                status: [],
                rekening_bank: [],
                jenis_pembayaran: [],
                semester: [],
                jm: "0"

            }
        },
        computed: {
            sortOptions() {
                // Create an options list from our fields
                return this.fields
                    .filter(f => f.sortable)
                    .map(f => ({
                        text: f.label,
                        value: f.key
                    }))
            },
        },
        mounted() {
            // Set the initial number of items
            this.loadData();
            this.get_jenis_pembayaran();
            this.get_semester();
            this.get_rekening_bank()
        },
        methods: {
            async simpan(input) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/setup/periode_pembayaran/insert',
                        data: {
                            id_semester: input.id_semester.id_semester,
                            id_rekening: input.id_rekening.id,
                            id_jenis_pembayaran: input.id_jenis_pembayaran.id,
                            tgl_mulai: input.tgl_mulai,
                            tgl_selesai: input.tgl_selesai,
                            kelas: input.kelas.id_kelas,
                            nominal: input.nominal,
                            keterangan: input.keterangan
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.loadData()
                        self.notification('info', "", response.data.message);
                        self.input = {}

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async get_jenis_pembayaran() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/setup/periode_pembayaran/jenis_pembayaran',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.jenis_pembayaran = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async get_semester() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/setup/periode_pembayaran/semester',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.semester = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async get_rekening_bank() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/setup/periode_pembayaran/rekening_bank',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.rekening_bank = response.data.result;

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async getEdit(id, jm) {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/setup/periode_pembayaran/get_edit',
                        data: {
                            id: id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.input = response.data.result;
                        self.jm = jm

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },

            async update(input) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/setup/periode_pembayaran/update',
                        data: {
                            id : input.id,
                            id_semester: input.id_semester.id_semester,
                            id_rekening: input.id_rekening.id,
                            id_jenis_pembayaran: input.id_jenis_pembayaran.id,
                            tgl_mulai: input.tgl_mulai,
                            tgl_selesai: input.tgl_selesai,
                            kelas: input.kelas.id_kelas,
                            nominal: input.nominal,
                            keterangan: input.keterangan
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.loadData()
                        self.notification('info', "", response.data.message);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            Rp(value) {
                let val = (value / 1).toFixed(0).replace('.', ',')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
            },
            async updateStatus(id) {
                const self = this;
                await axios({
                        method: 'POST',
                        url: '/api/setup/periode_pembayaran/update_status',
                        data: {
                            id: id
                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.loadData();
                        self.notification('warning', "info", response.data.message);

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            async loadData() {
                const self = this;
                await axios({
                        method: 'PUT',
                        url: '/api/setup/periode_pembayaran/load',
                        data: {

                        },
                        headers: {
                            'Authorization': self.isAuthenticated
                        }
                    })
                    .then(function (response) {
                        self.items = response.data.result;
                        self.totalRows = self.items.length

                    }).catch(err => {
                        self.pesan = err.message;
                        self.notification('warning', "Error", err.message);
                    });
            },
            info(item, index, button) {
                this.infoModal.title = `Row index: ${index}`
                this.infoModal.content = JSON.stringify(item, null, 2)
                this.$root.$emit('bv::show::modal', this.infoModal.id, button)
            },
            resetInfoModal() {
                this.infoModal.title = ''
                this.infoModal.content = ''
            },
            onFiltered(filteredItems) {
                // Trigger pagination to update the number of buttons/pages due to filtering
                this.totalRows = filteredItems.length
                this.currentPage = 1
            },
        },
    }
</script>
<style>
    .bg-card {
        background: #dbdbdb;
    }
</style>